<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-05-07 19:34:51
-->
<template>
  <div class="container-warp">
    <!-- 头部筛选--开始 -->
      <el-card class="card-pd" shadow='never'>
        <div slot="header">
          <el-form  class="form-item-no-mb" :model="searchForm"  ref="searchForm" inline >
            <el-form-item label="考试名称">
              <el-input v-model="searchForm.examName" placeholder="请输入考试名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="考试人员">
              <el-input v-model="searchForm.testerName" placeholder="请输入考试人员" clearable></el-input>
            </el-form-item>
            <el-form-item label="学习计划">
              <el-input v-model="searchForm.planName" placeholder="请输入学习计划" clearable></el-input>
            </el-form-item>
            <el-form-item label="考试时间" prop="startTime">
              <el-date-picker
                v-model="examTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="_selectExamTime">
              </el-date-picker>
            </el-form-item>
              <el-button @click="_reset">重置</el-button>
              <el-button type="primary" @click="_search">查询</el-button>
          </el-form>
        </div>
        <div class="export-btn">
          <el-button type="primary" @click="_exportExamRecord()">导出</el-button>
        </div>
      </el-card>
      <!-- 头部筛选---结束 -->
      <!-- 表格区域---开始 -->
      <div class="table-box">
      <el-table :data="examRecordData" height="590" @selection-change="_selectTableData" :row-key="_getRowKeys">
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column
          v-for="(item, index) in tableColumns"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
          :show-overflow-tooltip="item.overflow"
        >
          <template slot-scope="scope">
            <span v-if="item.prop === 'startTime'">
              {{ scope.row.startTime?`${scope.row.startTime} 至 ${scope.row.endTime}`:'—' }}
            </span>
            <span v-else-if="item.prop === 'consultFlag'">
              {{ scope.row.consultFlag == 0?'未查看':scope.row.consultFlag == 1?'已查看':'—' }}
            </span>
            <span v-else>
              {{ scope.row[item.prop]?scope.row[item.prop]:'—' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="80">
          <template slot-scope="scope">
              <el-button type="text" class="text-btn" @click="_detailExamBtn(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页---start -->
        <el-pagination
          class="fx-row-end pt16"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[5, 10, 20, 30, 40]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="recordTotal">
        </el-pagination>
        <!-- 分页---end -->
    </div>
    <!-- 表格区域---结束 -->
  </div>
</template>
<script>
import { getExamRecordApi, getExamRecordExportApi, getMarkViewStatusApi } from '@/api/examApi'
import { exportBtn } from '@/utils/util'
export default {
  components: { },
  data () {
    return {
      examTime: '', // 考试时间
      searchForm: {
        examName: '', // 计划名称
        testerName: '', // 计划类型
        planName: '', // 计划日期
        startExamTime: '', // 考试开始时间
        endExamTime: '', // 考试结束时间
        page: 1,
        pageSize: 10
      },
      examRecordData: [], // 表格数据考试记录数据
      recordTotal: 0,
      exportExamIds: [], // 导出考试id
      exportUserIds: [], // 导出员工id
      // 表格字段
      tableColumns: [
        { prop: 'employeeName', label: '姓名', width: '150px', overflow: true },
        { prop: 'entryTime', label: '入职时间', overflow: true },
        { prop: 'courseNames', label: '参与培训课程', overflow: true },
        { prop: 'studyDuration', label: '学习时长', overflow: true },
        { prop: 'mark', label: '考核成绩' },
        { prop: 'instructorNames', label: '带教人员', width: '150px', overflow: true },
        { prop: 'examName', label: '考试名称', overflow: true },
        { prop: 'planName', label: '学习计划', overflow: true },
        { prop: 'startTime', label: '考试时间', width: 330 },
        { prop: 'consultFlag', label: '查看状态', width: 80 }
      ]
    }
  },
  created () {
    if (this.$route.query.planName) {
      this.searchForm.planName = this.$route.query.planName
    }
    this._getExamRecordList()
  },
  mounted () {
  },

  methods: {
    // 查询
    _search () {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this._getExamRecordList()
    },
    // 获取考试记录列表 -- 查询
    _getExamRecordList () {
      getExamRecordApi(this.searchForm).then((res) => {
        if (res.code === 200) {
          this.examRecordData = JSON.parse(JSON.stringify(res.object.results))
          this.examRecordData = this.examRecordData.map((item) => {
            return {
              id: `${item.employeeId}${item.examInfoId}`,
              ...item
            }
          })
          this.recordTotal = res.object.total
        }
      })
    },
    // 选择题目
    _selectTableData (val) {
      this.selectedQuestionKeys = val
      this.exportExamIds = this.selectedQuestionKeys.map((item) => { /// / 导出考试id
        return item.examInfoId
      })
      this.exportUserIds = this.selectedQuestionKeys.map((item) => { // 导出员工id
        return item.employeeId
      })
      this.exportExamIds = this._unique(this.exportExamIds)
      this.exportUserIds = this._unique(this.exportUserIds)
    },
    // 去重函数
    _unique (arr) {
      return arr.filter(function (item, index, arr) {
        // 当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index
      })
    },
    _getRowKeys (row) {
      return row.id
    },
    // 考试记录-分页
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._getExamRecordList()
    },
    // 考试记录-分页
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._getExamRecordList()
    },
    // 选择考试时间
    _selectExamTime (a) {
      if (a) {
        this.searchForm.startExamTime = a[0]
        this.searchForm.endExamTime = a[1]
      } else {
        this.searchForm.startExamTime = ''
        this.searchForm.endExamTime = ''
      }
    },
    // 重置表单数据
    _reset () {
      this.searchForm = {
        examName: '', // 计划名称
        testerName: '', // 计划类型
        planName: '', // 计划日期
        startExamTime: '', // 考试开始时间
        endExamTime: '', // 考试结束时间
        page: 1,
        pageSize: 10
      }
      this.examTime = ''
      this._getExamRecordList()
    },
    // 导出
    _exportExamRecord () {
      // 导出
      const params = {
        ...this.searchForm,
        page: undefined,
        pageSize: undefined,
        examInfoIds: this.exportExamIds,
        testerIds: this.exportUserIds

      }
      exportBtn(getExamRecordExportApi, { ...params }, '试卷记录导出')
    },
    // 操作按钮---详情
    _detailExamBtn (row) {
      getMarkViewStatusApi({ examInfoId: row.examInfoId, employeeId: row.employeeId }).then((res) => {
        if (res.code === 200) {
          console.log('查看成功！')
        }
      })
      this.$router.push({
        path: '/examRecordDetail',
        query: {
          examInfoId: row.examInfoId,
          employeeId: row.employeeId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style.scss";
.card-pd{
  /deep/.el-card__header{
    padding: 16px 24px;
  }
  /deep/ .el-card__body{
    padding: 16px 24px;
  }
}
  .form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 16px;
    margin-right: 32px;
  }
  /deep/.el-form-item__label{
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.90);
  }
}
.export-btn{
  /deep/.el-button{
    margin-left: 0px !important;
  }
}
/deep/.el-button{
  margin-left: 0px!important;
  margin-right: 16px !important;
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--default{
  color:  rgba(0, 0, 0, 0.90);
}
.plan-list-box{
  margin-top: 16px;
  padding: 0 24px;
  background-color: #ffffff;
  border-radius: 4px;
  height: 650px;
  /deep/.el-tabs__nav {
    height: 55px;
  }
  /deep/.el-tabs__item {
    line-height: 55px;
  }
}

.paper-box{
  padding: 16px 24px;
  margin-bottom: 24px;
  background: linear-gradient(to bottom,#F0F8FF,#FFFFFF);
  border: 1px solid #E7F4FF;
  border-radius: 8px;
  .paper-title{
    display: flex;
    justify-content: space-between;
    .paper-title-left{
      display: flex;
      align-items: center;
      .paper-title-mc{
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
      .paper-title-bq{
        padding: 3px 12px;
        margin-left: 16px;
        font-size: 12px;
        color: #FFFFFF;
        border-radius: 8px 0px 8px 0px;
        background: linear-gradient(to right, #31ADF6,#5096FF);
      }
    }
  }
  .paper-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    .paper-content-left{
      .paper-content-left-ms{
        margin-bottom: 12px;
        width: 450px;
        font-size: 14px;
        color: #666666;
      }
      .paper-content-left-xx{
        display: flex;
        align-items: center;
        font-size: 12px;
        .line{
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background-color: #DCDCDC;
        }
      }
    }
  }
}
.exam-dialog{
  /deep/.el-dialog{
    width: 400px;
    height: 276px;
  }
  /deep/.el-dialog__title{
    font-size: 16px;
    color: hsla(0, 0%, 0%, 0.9);
    font-weight: 500;
  }
  /deep/.el-dialog__body{
    padding: 20px 30px 14px 24px;
  }
}
.table-box{
  background-color: #fff;
  padding-bottom: 10px;
}
</style>
